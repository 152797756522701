import { Link } from "gatsby";
import React from "react";

import Subscribe from "../components/forms/subscribe";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Error = () => {
  return (
    <Layout>
      <Seo title="Page non trouvée" description="cette page n'existe pas" />
      <div className="page error-page">
        <h1>oops la page n'existe pas</h1>
        <Link to="/" className="btn">
          Accueil
        </Link>
      </div>
      <Subscribe />
    </Layout>
  );
};

export default Error;
